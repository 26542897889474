.bg_footer {
    background-color: #a20000;
    padding: 40px;
  }
  .footer-item h4 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
  }
  .footer-list li {
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 30px;
  }
  .footer-list li a {
    text-decoration: none;
}
a{
    color: #fff;
}
  .footer-list li :hover {
    color: rgb(207, 191, 191);
  }
  .footer-list   {
    width: 260px;
    font-size: 15px;
    font-size: medium;
    color: #fff;
    line-height: 30px;
  }
  .footer-list .social_icons {
    display: flex;
  }
  .copyright_footer {
    background-color: rgb(156, 156, 194);
    width: 100%;
    padding: 20px;
  }
  .footer-logo-heading h3 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }


.social_icons {
    display: flex;
    gap: 10px;
}

.icon {
    font-size: 1.5em;
    transition: transform 0.2s;
    border-radius: 50%;
    overflow: hidden;
}

.icon:hover {
    transform: translateY(-5px); /* Adjust the value based on your preference for the upward movement */
}
p {
  margin-top: 0;
 margin-bottom: 0rem; 
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0rem; 
}
/* Regular styles for footer */
/* Ensure that your regular styles for the footer are applied here */

/* Media query for iPad Pro landscape mode */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  /* Adjust container width to fit within the viewport */
  .container-fluid {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }

  /* Adjust column widths to fit within the viewport */
  .col-lg-3 {
    width: 25%;
    float: left;
  }

  /* Adjust other specific styles as needed to fit the footer within the viewport */
  /* For example, reduce padding or margins, adjust font sizes, etc. */
}




