
.card-img:hover {
    transform: scale(0.9);
  }
  .card-img-1 {
    width: 79%;
}
.h1, h1 {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .dataform {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .dataform form label {
    font-size: 14px; /* Reduce font size for labels on smaller screens */
  }

  .dataform form input,
  .dataform form select,
  .dataform form textarea {
    font-size: 14px; /* Reduce font size for form inputs on smaller screens */
  }
}
.contact-info {
  border: 2px solid red;
  background-color: #a20000;
  color: white;
  padding: 30px;
  text-align: center;
  border-radius: 20px;
}

.contact-info a {
  text-decoration: none;
  color: white;
}

.contact-info h1 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 16px;
  margin-top: 5px;
}




