.py-lg-5 {
    padding-top: 3rem!important;
    padding-bottom: 1rem!important;
}


/* .container{
    box-sizing: content-box;
} */
/* Media query for iPad landscape mode */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
    /* Adjust styles for landscape mode */
  
    /* Ensure that content does not overflow horizontally */
    body {
      overflow-x: hidden;
    }
  
    /* Adjust width and margins of elements to fit within the viewport */
    .container {
      width: 100%; /* Set width to 100% to prevent overflow */
      padding-left: 15px; /* Example padding to account for Bootstrap container padding */
      padding-right: 15px; /* Example padding to account for Bootstrap container padding */
      margin-left: auto;
      margin-right: auto;
    }
  
    /* Adjust width of specific elements if needed */
    .row,
    .col-lg-8 {
      max-width: 100%; /* Set max-width to 100% to prevent overflow */
    }
  }