.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #a20000;
    color: #fff;
    padding: 15px;
    text-align: center;
  }
  
  .cookie-consent p {
    margin: 8px;
  }
  
  .cookie-consent button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cookie-consent button:hover {
    background-color: #ddd;
  }