.topHeader{
    background-color: #a20000;
    text-align: center;
}
.topHeader img{
    width: 20%;
}


/* ----------------------------------------new custom css -------------------- */


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .topHeader{
        display: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {"..."} */