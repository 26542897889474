.slider-container {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
} 


  .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
} 

 
   /* .slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
} */
.slider-image {
  margin-right: 10px; 
} 
/* .slick-slide {
  margin-right: 15px; /* Adjust the margin value as needed for space between images */
/* } */

/* .slick-prev,
.slick-next {
  z-index: 1;
} */ 
.custom-slider {
  & > div {
    padding: 0 10px; /* Adjust the padding value as needed for spacing between slides */

}   }

.slick-prev{
  left: -25px !important;
}
.slick-next{
  right: -5px !important;
}
.slick-prev,.slick-next{
  display: none;
}
.slick-prev:before, .slick-next:before{
  color: black !important;
  line-height: 2;
  font-size: 25px;
}
@media (max-width: 1300px) {
  .slick-prev:before,.slick-next:before{
    display: none;
  }
}
.navbar-toggler{
  margin: 0 20px;
}
.slick-slider {
  margin-left: auto; /* Auto left margin */
  margin-right: auto; /* Auto right margin */
  max-width: 1200px; /* Adjust as needed */
}

/* Adjust the slide images */
.slider-image {
  margin-right: 0; /* Remove right margin to prevent extra space */
}
