.slider-image {
    border: 2px solid #000;
    border-radius: 8px;
    /* margin-right: 15px; */
    width: 100%;
    height: 100%;
  }
  /* .slick-slide {
    margin-right: 10px;
  } */
  