
.block-contents .section-title h2 {
    font-size: 60px;
    line-height: 1.3;
    text-transform: capitalize;
    color: #FFFFFF;
}
.elementor-post__read-more {
    color: #A20000;
    margin-bottom: 0px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    opacity: 0.7;
}
.elementor-post__meta-data {
    line-height: 1.3em;
    font-size: 12px;
    margin-bottom: 13px;
    color: #adadad;
}
.elementor-post-avatar{
    margin-right: 10px;
}