.slick-initialized .slick-slide {
    display: grid;
}
.slick-prev, .slick-next {
    font-size: 35px;
    color: black;
  
}
.container {
    max-width: 1350px!important;
}
.container {
    max-width: 1348px;
}
.slick-prev{
    left: 20px;
}
.slick-next{
    right: 20px;
}
.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 2rem!important;
}

/* #tigerneo2 {
    text-align: right;
} */

#tigerneo2 .pic img {
    max-width: 100%;
    height: 100%;
}

#tigerneo2 .fl,
#tigerneo2 .fr {
    display: flex;
    align-items: center;
    justify-content: center;
}

#tigerneo2 .fl ul,
#tigerneo2 .fr ul {
    list-style: none;
    padding: 0;
}

#tigerneo2 .fl ul li,
#tigerneo2 .fr ul li {
    margin: 10px 0;
    font-size: 18px;
    display: flex;
    align-items: center;
}

#tigerneo2 .fl ul li i,
#tigerneo2 .fr ul li i {
    width: 40px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #a20000;
    margin: 0 10px;
    border-radius: 50%;
    font-weight: bold;
}
#tigerneo2 .list {
    padding-left: 0;
}

#tigerneo2 .list ul {
    list-style: none;
    margin-left: 0;
}

#tigerneo2 .list ul li {
    margin-bottom: 18px;
}

@media (max-width: 767px) {
    #tigerneo2 .fl,
    #tigerneo2 .pic,
    #tigerneo2 .fr {
        text-align: center;
    }

    #tigerneo2 .text-right {
        text-align: right;
    }

    #tigerneo2 .text-left {
        text-align: left;
    }
}
#tigerneo2  {
    background-color: #f5f5f5; /* Background color */
    padding: 20px;
    border-radius: 8px; /* Add border-radius for rounded corners */
}
.img-fluid.hover-effect {
    transition: transform 0.3s ease;
  }
  
  /* Define the hover effect */
  .img-fluid.hover-effect:hover {
    transform: scale(0.9); /* Increase the size of the image on hover */
  }
  

