/* .pm-card{
    max-width: 1000px;

}
.custom-container {
    position: relative;
    background: white;
  }
  
  .custom-container::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: url('../../assets/images/Sitpur\ 2.jpg') no-repeat center center;
    background-size: cover;
    z-index: 0;
  }
  
  .feature-card {
    border: none;
    position: relative;
    z-index: 1;
  }
  
  .feature-card .card-body {
    padding: 1.5rem;
  }
  
  .feature-card .card-title {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  
  .feature-card .card-text {
    font-size: 0.9rem;
  }
  
  .container {
    max-width: 1200px;
  } */
   
/* .custom-container {
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto;
  padding: 0 15px; 
} */

.custom-card {
    width: 100%;
    max-width: 100%;
  }

  .img-responsive {
    width: 100%;
    height: auto;
  }
  .feature-icon {
    width: 50px; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Example media query to adjust size on smaller screens if needed */
  @media (max-width: 767px) {
    .feature-icon {
      width: 40px; /* Adjust size for smaller screens */
    }
  }
  
  .feature-carousel {
    position: relative;
    top: 5%;
    left: 0;
    transform: translateY(-50%);
    margin: 0 auto;
  }

  .feature-card {
    flex: 1;
    margin: 10px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: #ECFFE6;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 2px;
  }

  .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270%200%2016%2016%27%20fill=%27%23000%27%3e%3cpath%20d=%27M4.646%201.646a.5.5%200%200%201%20.708%200l6%206a.5.5%200%200%201%200%20.708l-6%206a.5.5%200%200%201-.708-.708L10.293%208%204.646%202.354a.5.5%200%200%201%200-.708z%27/%3e%3c/svg%3e');
  }

  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270%200%2016%2016%27%20fill=%27%23000%27%3e%3cpath%20d=%27M11.354%201.646a.5.5%200%200%201%200%20.708L5.707%208l5.647%205.646a.5.5%200%200%201-.708.708l-6-6a.5.5%200%200%201%200-.708l6-6a.5.5%200%200%201%20.708%200z%27/%3e%3c/svg%3e');
  }

  .carousel-control-prev {
   left: -10px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    margin: 0;
    width: 5%;
  }

  .carousel-indicators [data-bs-target] {
    display: none;
  }

  @media (max-width: 768px) {
    .feature-card {
      flex: 0 0 100%;
    }
  }
  @media (max-width: 768px) {
    .carousel-image {
      width: 100%;
      /* max-height: 250px; */
    }
  
    .custom-carousel {
      position: static;
      top: 365px !important; /* Position adjustment for smaller screens */
      border: none; /* Remove border if not needed */
      margin-top: 20px; /* Adjust as needed */
    }
}
@media (min-width: 768px) And (max-width:900px) {
    .custom-carousel {
       position: static;  
       margin-top: -10px;  
    margin-left: -20px; 
    width: 820px;
      /* position: static; */
     /* Adjust as needed */
      
    }
    .carousel-control-prev, .carousel-control-next {
      display: none;
    }
  }
  
  
  /* Adjust styles for extra small screens */
  @media (min-width: 576px) And (max-width: 768px) {
    .custom-carousel {
        top: 130px !important;
  
      left: 25px !important;
      width: 780px; 
    }
    .fixed-height{
        max-height: 100%;
    }
    
  }
  @media (max-width: 576px) {
    .custom-carousel {
        top: 295px !important;
      /* position: static; */
     /* Adjust as needed */
      left: 30px !important;
      width: 325px; /* Centering the carousel */
    }
    .fixed-height{
        max-height: 100%;
    }
    .carousel-control-prev, .carousel-control-next {
      display: none;
    }
    
  }
  /* @media (min-width: 992px) and (max-width :1200px) {
    .col-md-3 {
        flex: 0 0 auto;
        width: 34%;
    }
}
  */

  

/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  } */
  /* .custom-card{
    width: 1000px;
  }
  .content-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    max-width: 1200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
  } */

  /* .image-container, .text-container {
    flex: 1;
    min-width: 300px; 
    margin: 10px;
  } */

  /* .image {
    width: 100%;
    height: auto;
    max-width: 500px; 
  }

  .download-button {
    background-color: #a20000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .download-button:hover {
    background-color: #900000;
  } */

  /* @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 576px) {
    .child-card {
        flex-direction: column;
    }
    .image {
        margin-bottom: 10px;
    }
} */

  