.card-img {
    max-height: 100px; 
    object-fit: contain;
    margin: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .card-img:hover {
    transform: scale(1.1);
  }
  .card {
    padding: 20px; 

  }
  .row-gutter {
    margin-left: 30px; 
    margin-right: 30px;
  }
