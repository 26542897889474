/* YourModalStyles.css */

/* Styles for the semi-transparent overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the modal content */
.modal-content {
  background: #fff;

  border-radius: 5px;
  max-width: 90%; 
  max-height: 90%;
  overflow: auto;
}

/* Custom modal styles */
.custom-modal {
 
  max-height: 90vh; 
}

/* Additional styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust margins for the video play button */
  .video-play-btn {
      margin-top: -70px; /* Adjust this value according to your requirement */
  }
}
