*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.history-banner{
    background-color: #A20000 !important;
    opacity: 0.83;
    
}
.history-img img{
    z-index: -1;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}