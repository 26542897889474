.navbar-light input {
    box-sizing: border-box;
    border: 3px solid black;
    
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  
  input[type=text]:focus {
    border: 3px solid #555;
  }
  .box__label--rotate h5{
    writing-mode: vertical-rl;
  text-orientation: sideways-right;
  font-style: normal !important;
  transform: rotate(180deg);
  margin: 0;
  padding: 8px;
  background-color: #a20000; 
  color: #ffff;
  border-radius: 10px 0 10px 0;
  }
  .bg-color{
    background-color: #d7d7d7;
    padding: 20px;
  }
  .cheak-boxHeading{
    color: black;
  }
  .aboutSolar h2{
    font-size: 38px;
}