/* SolarMod.css
.animated {
    animation-duration: 1s;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
  }
   */
   .card {
    padding: 0px!important;
   
  

}
.text-body-secondary {
  --bs-text-opacity: 1;
  color: rgb(0 0 0)!important;
}
.card-img-top:hover {
  transform: scale(0.9); /* Increase the size of the card image on hover */
  transition: transform 0.3s ease; /* Apply a smooth transition */
}
/* solarmod.css */

.solar-card-container {
  display: flex;
  align-items: stretch;
}

.solar-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.solar-card .card-body {
  flex: 1;
}
@media (max-width: 768px) {

  .fw-bold {
      margin-top: -70px;
  }
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap value as needed */
}

