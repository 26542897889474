.container {
    max-width: 1135px;
}
.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 0rem!important; 
}
.card {
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the card horizontally */
}
