.contact-icons {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
  }
  
  .icon {
    background-color: #a20000; /* WhatsApp color */
    color: #fff;
    padding: 10px;
    border-radius: 10px; /* Use half of the desired square size for border-radius */
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .icon.open {
    background-color:#a20000 /* Active color */
  }
  .icon:hover {
    transform: scale(1.1);
  }
  